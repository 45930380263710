import React, {useEffect} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'aos/dist/aos.css'
import "../scss/style.scss";
import type {AppProps} from 'next/app'
import AppWrapper from "../AppWrapper";
import ToastProvider from "../atoms/Toast/ToastProvider";
import {GlobalProvider} from "../providers/GlobalProvider";
import {IProps} from "../types/homePageTypes";
import AOS from 'aos'
import ErrorBoundary from "../ErrorBoundary";


export default function App({Component, pageProps}: AppProps) {
    useEffect(() => {
        AOS.init({duration: 1000})
    }, [])

    return (
        <ErrorBoundary>
            <AppWrapper>
                <GlobalProvider value={pageProps as unknown as IProps}>
                    <ToastProvider>
                        <Component {...pageProps} />
                    </ToastProvider>
                </GlobalProvider>
            </AppWrapper>
        </ErrorBoundary>
    );
}
