const settingConstants = {
    Phone: "general.info.general.phone",
    Email: "general.info.general.footer_email",
    Footer_Address: "general.info.general.footer_address",
    Custom_Css: "general.content.custom_scripts.custom_css",
    Custom_Javascript: "general.content.custom_scripts.custom_javascript",
    Custom_Analytics_GTAG: "general.content.custom_scripts.custom_google_analytic",
    Gdpr_Content: "general.gdpr.general.content",
    Gdpr_Title: "general.gdpr.general.title",
    Gdpr_Active: "general.gdpr.general.active",
    Out_Of_Stock: "catalog.products.homepage.out_of_stock_items",
    Guest_Checkout: "catalog.products.guest-checkout.allow-guest-checkout",
    Backorders: "catalog.inventory.stock_options.backorders",
    Blog_Active: "theme.blog.active",
    Contact_Us_Active: "theme.contact_us.active",
    Subscribe_Active: "theme.subscription.active",
    Tracking_Active: "sales.tracking.tracking.active",
    Linkedin_Active: "customer.settings.social_login.enable_linkedin",
    Facebook_Active: "customer.settings.social_login.enable_facebook",
    Twitter_Active: "customer.settings.social_login.enable_twitter",
    Github_Active: "customer.settings.social_login.enable_github",
    Google_Active: "customer.settings.social_login.enable_google",
    Cache_Version: "general.cash_control.version",
    Footer_Content: "general.content.footer.footer_content",
    Footer_Powered: "general.content.footer.footer_powered",
    Infinite_Scroll: "catalog.categories.infinite_scroll.infinite_scroll",
    Recaptcha_active: "general.recaptcha.general.active",
    Recaptcha_key: "general.recaptcha.general.site_key",
    Blog_meta_title: "blog.meta.options.meta_title",
    Blog_meta_description: "blog.meta.options.meta_description",
    Blog_meta_keywords: "blog.meta.options.meta_keywords",
    Suspend_Status: "general.suspend.status",
}

module.exports = settingConstants